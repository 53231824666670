import React from "react";
import "./style.css";
import image3 from "../../images/3.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item me-5 dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/allfood"
                  role="button"
                  id="navbarFoodDropdown" // Ensure this ID is unique
                >
                  Food
                </Link>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarFoodDropdown"
                >
                  <li className="dropdown-item dropdown">
                    <Link
                      className="dropdown-toggle"
                      to="/catfood"
                      id="navbarCatsDropdown"
                      role="button"
                    >
                      Cat food
                    </Link>
                  </li>
                  <li className="dropdown-item dropdown">
                    <Link
                      className="dropdown-toggle"
                      to="/dogfood"
                      id="navbarDogsDropdown"
                      role="button"
                    >
                      Dog food
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="nav-item me-5  dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="alltreats"
                  id="navbarTreatsDropdown"
                  role="button"
                  aria-expanded="false"
                >
                  Treats
                </Link>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarTreatsDropdown"
                >
                  <li>
                    <Link class="dropdown-item" to="/cattreats">
                      Cat treats
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/dogtreats">
                      Dog treats
                    </Link>
                  </li>
                </ul>
              </li>

              <li class="nav-item me-5  dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="/alltoysandbowls"
                  id="navbarTreatsDropdown"
                  role="button"
                >
                  Toys & Bowls
                </Link>
              </li>
              <li class="nav-item me-5  dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="/beds"
                  id="navbarTreatsDropdown"
                  role="button"
                >
                  Beds
                </Link>
              </li>

              <li class="nav-item me-5  dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="/litters"
                  id="navbarTreatsDropdown"
                  role="button"
                >
                  Litters
                </Link>
              </li>

              <li class="nav-item me-5  dropdown">
                <Link
                  a
                  class="nav-link dropdown-toggle"
                  to="/healthcare"
                  id="navbarTreatsDropdown"
                  role="button"
                >
                  HealthCare
                </Link>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarTreatsDropdown"
                >
                  <li>
                    <Link class="dropdown-item" to="/earcare">
                      Ear Care
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/eyecare">
                      Eye Care
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/grooming">
                      Grooming
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/dentalcare">
                      Dental Care
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/fleasandticks">
                      Ticks & Fleas
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item me-5  dropdown">
                <Link
                  class="nav-link dropdown-toggle"
                  to="/accessories"
                  id="navbarTreatsDropdown"
                  role="button"
                >
                  Accessories
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
