import React, { useEffect, useState } from 'react';
import './style.css';
import axios from 'axios';
import Slider from 'react-slick';
import ProductCard from './../ProductCard/ProductCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import image3 from '../../images/3.png'; 
import image5 from '../../images/5.jpg'; 
import image1 from '../../images/1.jpg'; 
import image6 from '../../images/6.jpg'; 

export default function Home() {
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [dogProducts, setDogProducts] = useState([]);
  const [catProducts, setCatProducts] = useState([]);
  const [error, setError] = useState(null); // Error handling state

  // API base URL from environment variables
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;;

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await axios.get(`${apiBaseUrl}/products`);
      console.log(data);

      // Trending Products
      setProducts(data.slice(0, 10));

      // New Arrivals
      setNewProducts(data.slice(20, 30));

      // Products filtered for Cats
      const filteredCatProducts = data
        .filter((product) => product.name.toLowerCase().includes("cat"))
        .slice(0, 10);
      setCatProducts(filteredCatProducts);

      // Products filtered for Dogs
      const filteredDogProducts = data
        .filter((product) => product.name.toLowerCase().includes("dog"))
        .slice(0, 10);
      setDogProducts(filteredDogProducts);
    } catch (err) {
      console.error(err);
      setError("Failed to load products. Please try again later.");
    }
  };

  // Slider settings
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Reusable Slider Section
  const SliderSection = ({ title, products, image }) => (
    <div className="mt-5">
      <img src={image} className="w-100 main-image" alt="" />
      <div className="container">
        <div className="row">
          <h1 className="text-center trending">{title}</h1>
          {products.length > 0 ? (
            <Slider {...settings}>
              {products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </Slider>
          ) : (
            <p className="text-center">No products available.</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {error && <div className="alert alert-danger text-center">{error}</div>}

      <SliderSection
        title="Trending Products"
        products={products}
        image={image5}
      />

      <SliderSection
        title="New Arrivals"
        products={newProducts}
        image={image3}
      />

      <SliderSection
        title="Trending In Cats"
        products={catProducts}
        image={image6}
      />

      <SliderSection
        title="Trending In Dogs"
        products={dogProducts}
        image={image1}
      />
    </>
  );
}
