import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null);
    const [userName, setUserName] = useState(localStorage.getItem('userName') || "");
    

    const logout = () => {
        setToken(null);
        setUserName("");
        
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        
    };

    return (
        <AuthContext.Provider value={{ token, setToken, userName, setUserName, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
