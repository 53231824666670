import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './style.css';
import BedsCard from './../BedsCard/BedsCards';

export default function AllBeds() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null); // Error state for error handling
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL from the environment variable

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios
            .get(`${API_BASE_URL}/products`) // Use the API base URL from the environment variable
            .then(({ data }) => {
                setProducts(data); // Set fetched products to state
            })
            .catch((err) => {
                console.error(err);
                setError('Failed to load products'); // Set error message if request fails
            });
    }

    // Filter the products to include only beds
    const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes('bed')
    );

    return (
        <>
            <div className="container mt-4">
                <h1 className="text-center trending">Beds</h1>
                {error && <div className="error-message">{error}</div>} {/* Show error message if any */}
                <div className="row d-flex justify-content-center">
                    {filteredProducts.length === 0 ? (
                        <p>No beds found</p> // Message if no beds are found
                    ) : (
                        filteredProducts.map((product) => (
                            <div className="col-md-2 mb-4" key={product.id}>
                                <BedsCard product={product} />
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
}
