import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './style.css';
import { Link } from 'react-router-dom';
import AccessoriesCard from './../AccessoriesCard/AccessoriesCard';

export default function Accessories() {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null); // State for handling errors
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Use the environment variable for the API base URL

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios
            .get(`${API_BASE_URL}/products`) // Use the environment variable in the API request
            .then(({ data }) => {
                setProducts(data); // Directly set data to state
            })
            .catch((err) => {
                console.log(err);
                setError('Failed to load products'); // Set error message if the request fails
            });
    }

    // Filter accessories based on name
    const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes('leash') ||
        product.name.toLowerCase().includes('collar') ||
        product.name.toLowerCase().includes('brush') ||
        product.name.toLowerCase().includes('crate')
    );

    return (
        <>
            <div className="container mt-4">
                <h1 className="text-center trending">Accessories</h1>
                {error && <div className="error-message">{error}</div>} {/* Display error if any */}
                <div className="row d-flex justify-content-center">
                    {filteredProducts.length === 0 ? (
                        <p>No accessories found</p> // Message if no accessories match the filter
                    ) : (
                        filteredProducts.map((product) => (
                            <div className="col-md-2 mb-4" key={product.id}>
                                <AccessoriesCard product={product} />
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    );
}
