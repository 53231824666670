import './App.css';
import Header from './Components/Header/Header';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Components/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar/Navbar';
import Login from './Components/Login/Login';
import SignUp from './Components/SignUp/SignUp';
import Cart from './Components/Cart/Cart';
import AllFood from './Components/Food/AllFood/AllFood';
import CatFood from './Components/Food/CatFood/CatFood';
import CatDryFood from './Components/Food/CatFood/CatDryFood';
import CatWetFood from './Components/Food/CatFood/CatWetFood';
import DogFood from './Components/Food/DogFood/DogFood';
import DogDryFood from './Components/Food/DogFood/DogDryFood';
import DogWetFood from './Components/Food/DogFood/DogWetFood';
import Footer from './Components/Footer/Footer';
import AllTreats from './Components/Treats/AllTreats/AllTreats';
import CatTreats from './Components/Treats/CatTreats/CatTreats';
import DogTreats from './Components/Treats/DogTreats/DogTreats';
import AllToysAndBowls from './Components/Toys&Bowls/AllToys&Bowls/AllToysAndBowls';
import Toys from './Components/Toys&Bowls/Toys/Toys';
import Bowls from './Components/Toys&Bowls/Bowls/Bowls';
import AllBeds from './Components/Beds/AllBeds/AllBeds';
import AllLitters from './Components/Litter/AllLitters/AllLitters';
import AllHealthcare from './Components/Healthcare/AllHealthcare/AllHealthcare';
import Ear from './Components/Healthcare/Ear/Ear';
import Eye from './Components/Healthcare/Eye/Eye';
import Grooming from './Components/Healthcare/Grooming/Grooming';
import TicksAndFleas from './Components/Healthcare/Ticks & Fleas/TicksAndFleas';
import Dental from './Components/Healthcare/Dental/Dental';
import Accessories from './Components/Accessories/Accessories/Accessories';
import ProductDetails from './Components/ProductDetails/ProductDetails';

import { AuthProvider, useAuth } from './Components/Context/AuthContext';

import AdminHome from './Components/Admin/AdminHome';
import AddProduct from './Components/Admin/AddProduct';
import AdminComponent from './Components/Admin/AdminComponent';
import UpdateProduct from './Components/Admin/UpdateProduct';
import UserProfile from './Components/Login/UserProfile';
import Search from './Components/Search/Search';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Checkout from './Components/Cart/Checkout';
import AdminOrder from './Components/Admin/AdminOrder';

function App() {
  return (
    <AuthProvider> {/* Move AuthProvider here to wrap the entire app */}
      <MainApp /> {/* MainApp contains your logic with useAuth */}
    </AuthProvider>
  );
}

function MainApp() {
  const { token } = useAuth(); // Now it's inside AuthProvider
  const location = useLocation(); // Get the current location

  const adminRoutes = ['/adminDashboard', '/adminHome', '/addproduct', '/updateproduct','/adminorder'];
  const navigate = useNavigate();

  const addToCart = (product) => {
    if (!token) {
        console.error("User is not authenticated. Redirecting to login.");
        navigate('/login');
        return;
    }

    axios.post(`http://127.0.0.1:8000/api/cart/${product.id}`, { 
        quantity: product.quantity
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
        console.log('Item added to cart:', response.data);
        alert('Item added to cart successfully!');
    })
    .catch(error => {
        console.error('Error adding to cart:', error);
        if (error.response && error.response.status === 401) {
            console.log("Session expired, please log in again.");
            navigate('/login');
        }
    });
};
  
  

  return (
    <>
      <Header />
      {/* Render Navbar only if the current path is not in adminRoutes */}
      {!adminRoutes.includes(location.pathname) && <Navbar />}
      {adminRoutes.includes(location.pathname) && <AdminComponent />}
      
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/cartpage" element={<Cart />} />
        <Route path="/allfood" element={<AllFood />} />
        <Route path="/catfood" element={<CatFood />} />
        <Route path="/catdryfood" element={<CatDryFood />} />
        <Route path="/catwetfood" element={<CatWetFood />} />
        <Route path="/dogfood" element={<DogFood />} />
        <Route path="/dogdryfood" element={<DogDryFood />} />
        <Route path="/dogwetfood" element={<DogWetFood />} />
        <Route path="/alltreats" element={<AllTreats />} />
        <Route path="/cattreats" element={<CatTreats />} />
        <Route path="/dogtreats" element={<DogTreats />} />
        <Route path="/alltoysandbowls" element={<AllToysAndBowls />} />
        <Route path="/toys" element={<Toys />} />
        <Route path="/bowls" element={<Bowls />} />
        <Route path="/beds" element={<AllBeds />} />
        <Route path="/litters" element={<AllLitters />} />
        <Route path="/healthcare" element={<AllHealthcare />} />
        <Route path="/earcare" element={<Ear />} />
        <Route path="/eyecare" element={<Eye />} />
        <Route path="/grooming" element={<Grooming />} />
        <Route path="/dentalCare" element={<Dental />} />
        <Route path="/fleasandticks" element={<TicksAndFleas />} />
        <Route path="/accessories" element={<Accessories />} />
        <Route path='/cartpage' element={<Cart/>} />
        <Route path="/search" element={< Search />} />
        <Route path="/checkout" element={<Checkout/>}/>
        <Route path="/productdetails/:id" element={<ProductDetails addToCart={addToCart} />} />

        <Route path='/profile' element={<UserProfile />} /> 

        {/* Admin Routes */}
        <Route path="/adminHome" element={<AdminHome />} />
        <Route path="/addproduct" element={<AddProduct />} />
        <Route path="/updateproduct/:x" element={<UpdateProduct />} />
        <Route path="/adminorder" element={<AdminOrder/>}/>
      </Routes>

      <Footer />
    </>
  );
}

export default App;
