import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import HealthcareCard from './../HealthcareCard/HealthcareCard';

export default function Eye() {
    const [products, setProducts] = useState([]);

    // Fetch the base API URL from the environment variable
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios
            .get(`${API_BASE_URL}/products`)  // Use the dynamic base URL
            .then(({ data }) => {
                setProducts(data.slice());
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <div className="container mt-4">
                <div className="row">
                    <h1 className="text-center trending">Eye Care</h1>
                    {/* Sidebar for category filter */}
                    <div className="col-md-3">
                        <div className="sidebar d-flex flex-column">
                            <h4>Category</h4>
                            <div className="links">
                                <Link to="/earcare">Ear Care</Link>
                            </div>
                            <div className="links">
                                <Link to="/eyecare">Eye Care</Link>
                            </div>
                            <div className="links">
                                <Link to="/grooming">Grooming</Link>
                            </div>
                            <div className="links">
                                <Link to="/dentalcare">Dental care</Link>
                            </div>
                            <div className="links">
                                <Link to="/fleasandticks">Fleas & Ticks</Link>
                            </div>
                        </div>
                    </div>

                    {/* Product Grid */}
                    <div className="col-md-9">
                        <div className="row">
                            {products
                                .filter((product) =>
                                    product.name.toLowerCase().includes('eye')
                                )
                                .map((product) => (
                                    <div className="col-md-3 mb-4" key={product.id}>
                                        <HealthcareCard product={product} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
