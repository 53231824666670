import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';
import './checkout.css';

export default function Checkout() {
    const { token } = useAuth();
    const [cartItems, setCartItems] = useState([]);
    const [shippingDetails, setShippingDetails] = useState({
        address: '',
        city: '',
        phone: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL from the environment variable

    useEffect(() => {
        if (!token) {
            navigate('/login');  // Redirect to login if not authenticated
        } else {
            // Fetch cart items if authenticated
            axios.get(`${API_BASE_URL}/cartpage`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                setCartItems(response.data.cartItems || []);
                setLoading(false);
            })
            .catch(error => {
                setError('Failed to load cart items. Please try again.');
                setLoading(false);
            });
        }
    }, [token, navigate, API_BASE_URL]);

    // Handle shipping details change
    const handleShippingChange = (e) => {
        setShippingDetails({
            ...shippingDetails,
            [e.target.name]: e.target.value
        });
    };

    // Place order after successful payment
    const placeOrder = () => {
        const orderData = {
            total: calculateTotal(),
            address: shippingDetails.address,
            city: shippingDetails.city,
            phone: shippingDetails.phone,
            cart_items: cartItems.map(item => ({
                product_id: item.id,
                quantity: item.quantity,
                price: item.price
            }))
        };
    
        axios.post(`${API_BASE_URL}/checkout`, orderData, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            alert('Order placed successfully!');
            navigate('/');
        })
        .catch(error => {
            console.error('Error placing order:', error);
            setError('Failed to place order. Please try again.');
        });
    };
    

    // Calculate total price of cart items
    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    // Handle loading and error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    if (cartItems.length === 0) {
        return (
            <section className="checkout-container mt-5">
                <h1>Checkout</h1>
                <div>Your cart is empty. Please add items to your cart.</div>
            </section>
        );
    }

    return (
        <section className="checkout-container mt-5">
            <h1>Checkout</h1>

            {/* Display cart items */}
            <div className="cart-items">
                {cartItems.map(item => (
                    <div className="cart-item" key={item.id}>
                        <img src={item.image_url} alt={item.name} />
                        <div>{item.name}</div>
                        <div>{item.price} EGP x {item.quantity}</div>
                    </div>
                ))}
            </div>

            {/* Shipping Details Form */}
            <div className="shipping-details">
                <h3>Shipping Details</h3>
                <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={shippingDetails.address}
                    onChange={handleShippingChange}
                    required
                />
                <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={shippingDetails.city}
                    onChange={handleShippingChange}
                    required
                />
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    value={shippingDetails.phone}
                    onChange={handleShippingChange}
                    required
                />
            </div>

            {/* Total Price */}
            <div className="total-price">
                <h3>Total: {calculateTotal()} EGP</h3>
            </div>

            {/* Confirm Order Button */}
            <button className="checkout-button" onClick={placeOrder}>Confirm Order</button>
        </section>
    );
}
