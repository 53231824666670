import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import './style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from './../ProductCard/ProductCard';

export default function ProductDetails({ addToCart }) {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [related, setRelated] = useState([]);

  useEffect(() => {
    getData(id);
  }, [id]);

  // Fetch product details and related products
  function getData(productId) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Get the base URL from environment variables

    axios.get(`${API_BASE_URL}/products`)
      .then(({ data }) => {
        const product = data.find(item => item.id === parseInt(productId));
        setDetails(product);

        // Update related products with a random selection
        updateRelatedProducts(data, productId);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // Update related products with random selection excluding the current product
  function updateRelatedProducts(data, productId) {
    const filteredProducts = data.filter(item => item.id !== parseInt(productId));
    const shuffledProducts = filteredProducts.sort(() => 0.5 - Math.random());
    const selectedProducts = shuffledProducts.slice(0, 5); // Limit to 5 related products
    setRelated(selectedProducts);
  }

  // Adjust product quantity
  const adjustQuantity = (amount) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + amount));
  };

  // Handle adding product to the cart
  const handleAddToCart = () => {
    if (typeof addToCart === 'function') {
      addToCart({ ...details, quantity });
    } else {
      console.error('addToCart is not a function');
    }
  };

  // Slider settings for related products
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-4">
            <img src={details.image_url} className="w-100" alt={details.name} />
          </div>
          <div className="col-md-7 offset-1 mt-5">
            <h1 className="h4 name">{details.name}</h1>
            <p className="price">Price: {details.price} EGP</p>
            <div className="quantity-selector my-3">
              <button onClick={() => adjustQuantity(-1)} className="btn btn-success buttons">-</button>
              <span className="mx-2 fs-5">{quantity}</span>
              <button onClick={() => adjustQuantity(1)} className="btn btn-success buttons">+</button>
            </div>
            <button className="btn btn-success mt-5 add" onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>

        {/* Related Products Slider */}
        <div className=''>
          <h3 className="other-products">Other Products</h3>
          <Slider {...settings}>
            {related.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
