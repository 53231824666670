// src/AdminPanel.js
import React from "react";
import "./CSS/admin.css";
import "./AdminHome";
import { Outlet } from "react-router-dom";

export default function AdminComponent() {
  return (
    <div id="wrapper">
      <div className="container-fluid mt-5 pt-5">
        <div className="row">
          {/* Sidebar */}
          <div className="col-2 sidebar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/adminHome">
                  Home
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/adminorder">
                  Orders
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="/addproduct">
                  Add New Product
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
