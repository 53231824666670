import React from "react";
import "./style.css";
export default function Footer() {
  return (
    <>
      <footer class="bg-success text-center mt-5 text-lg-start">
        <div class="container p-4">
          <div class="row mt-4">
            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 class="text-uppercas text-center">About Us</h5>

              <p className="text-center fw-lighter">
                At Toka, we provide everything your pets need—from healthy food
                to essential care items—giving you a trusted place to find love
                and attention for your furry friends. Our goal is to offer you
                the best products and support, so you can feel confident about
                your pet’s health and happiness.
              </p>
            </div>

            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 class="text-uppercase text-center">Contact Us</h5>

              <p className="text-center">
                  Number : 01102566380
                  Email : tokapet064@gmail.com
              </p>
            </div>
          </div>
        </div>

        <div class="text-center p-3 bg-sucess ">
          © 2024 Copyright:
          <a class="text-body" href="">
            {" "}
            Double M
          </a>
        </div>
      </footer>
    </>
  );
}
