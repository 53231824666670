import React from "react";
import "../Home/style.css";
import axios from "axios";
import { Link } from "react-router-dom";

export default function HomeCard({ product }) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Function to delete a product
  const deleteProduct = (productId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      axios
        .delete(`${API_BASE_URL}/deleteproduct/${productId}`)
        .then((response) => {
          console.log("Product deleted:", response.data);
          alert("Product deleted successfully!");
          // Optionally, you can reload the page or trigger a re-fetch of products
          window.location.reload(); // Reloads the page
        })
        .catch((error) => {
          console.error("Failed to delete product:", error);
          alert("An error occurred while deleting the product.");
        });
    }
  };

  return (
    <div className="product-card-container">
      <div className="product-card">
        <img
          src={product.image_url}
          className="image w-100"
          alt={product.name}
        />
        <div className="product-info">
          <h2 className="h6 text-center mt-4">{product.name}</h2>
          <h2 className="h6 text-center price mt-4">LE {product.price}</h2>
          <Link
            to={`/updateProduct/${product.id}`}
            className="btn button btn-success"
          >
            Update Product
          </Link>
          <button
            type="button"
            className="btn button btn-danger"
            onClick={() => deleteProduct(product.id)}
          >
            Delete Product
          </button>
        </div>
      </div>
    </div>
  );
}
