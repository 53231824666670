import axios from 'axios';
import Joi from 'joi';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext'; // Ensure this path is correct
import './style.css';

export default function Login() {
    let navigate = useNavigate();
    const { token, setToken, setUserName } = useAuth(); // Get token, setToken, and setUserName from AuthContext
    const [errorsList, setErrorsList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [dataInfo, setDataInfo] = useState({
        email: '',
        password: '',
    });

    // Check if user is already logged in
    useEffect(() => {
        if (token) {
            navigate('/home'); // Redirect to home if already logged in
        }
    }, [token, navigate]);

    // Function to update the input data
    function getData(e) {
        const { name, value } = e.target;
        setDataInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    // Function to handle form submission
    async function handleSubmit(e) {
        e.preventDefault();
        const validateResult = validateLoginForm();
        setErrorsList(validateResult?.error?.details || []);

        if (validateResult?.error) {
            console.log(validateResult.error.details);
            return; // Stop the function if validation fails
        }

        // Get the base API URL from environment variable
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

        try {
            const response = await axios.post(`${API_BASE_URL}/login`, {
                email: dataInfo.email,
                password: dataInfo.password,
            });

            // Log the response to verify its structure
            console.log('Response:', response);

            // Check if the response is successful
            if (response.status === 200) {
                // Set the token and name in  localStorage
                setToken(response.data.token);
                setUserName(response.data.first_name);
                localStorage.setItem('token', response.data.token); // Store token in localStorage  
                localStorage.setItem('userName', response.data.first_name);
                
                const adminEmail = 'admin@tokapet.com'; // Replace with the actual admin email
                if (dataInfo.email === adminEmail) {
                    navigate('/adminDashboard'); // Redirect to admin dashboard
                } else {
                    navigate('/home'); // Redirect to user home
                }
                
            } else {
                // Handle unexpected response structure
                console.error('Unexpected response:', response.data);
                setErrorMessage('Unexpected response from server.');
            }
        } catch (err) {
            // Log the full error response for debugging
            console.error('Login error:', err);

            // Set error message based on response or a fallback
            const message = err?.response?.data?.message || 'An error occurred.';
            setErrorMessage(message);
        }
    }

    // Function to validate the login form
    function validateLoginForm() {
        const schema = Joi.object({
            email: Joi.string()
                .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
                .required(),
            password: Joi.string()
                .pattern(new RegExp('^[a-zA-Z0-9!@#$%^&*]{3,30}$'))
                .required(),
        });
        return schema.validate(dataInfo, { abortEarly: false });
    }

    return (
        <div className="form-container">
            <div className="text d-flex justify-content-center">
                <h1 className='mt-5'>Welcome To Toka Pet!</h1>
            </div>
            <form className="create-account-form" onSubmit={handleSubmit}>
                {errorsList.map((err) => (
                    <h5 className='alert alert-danger' key={err.message}>{err.message}</h5>
                ))}
                {errorMessage && (
                    <h5 className='alert alert-danger'>{errorMessage}</h5>
                )}
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={getData}
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={getData}
                    />
                </div>
                <button type="submit" className="submit-button">Sign In</button>
            </form>
            <p className='mt-4'>Don't Have an account? 
                <Link to='/signup'> 
                    <span> Sign Up </span>
                </Link>
            </p>
        </div>
    );
}
