import React, { useEffect, useState } from "react";
import axios from "axios";
import "./UserProfile.css";

const UserProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [orders, setOrders] = useState([]); // New state for orders
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setError("No token found");
      setLoading(false);
      return;
    }

    // Get the base API URL from environment variable
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Fetch user profile
    axios
      .get(`${API_BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        setError(error);
        console.error("Error fetching user profile:", error);
      });

    // Fetch user orders
    axios
      .get(`${API_BASE_URL}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setOrders(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        console.error("Error fetching user orders:", error);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message || "Unable to fetch data"}</div>;

  return (
    <div className="profile-container">
      <div className="profile-card">
        <h1>User Profile</h1>
        <p>First Name: {profileData?.first_name}</p>
        <p>Second Name: {profileData?.second_name}</p>
        <p>Email: {profileData?.email}</p>

        <div className="orders-container">
          {orders.length > 0 ? (
            <ul className="order-list">
              {orders.map((order) => (
                <li key={order.id} className="order-item">
                  <h3>Order #{order.id}</h3>
                  <p>Date: {new Date(order.created_at).toLocaleDateString()}</p>
                  <p>Total: {order.total} EGP</p>
                  <p>Address: {order.address}</p>
                  <p>Status: {order.status}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
