import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './style.css';
import { useAuth } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Cart() {
    const { token } = useAuth();  // Get token from AuthContext
    const [cartItems, setCartItems] = useState([]);  // Initialize as empty array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();  // Use navigate to redirect if needed

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // API base URL from the environment variable

    useEffect(() => {
        console.log('Token:', token); // Log the token
        if (!token) {
            setError('You need to log in to access the cart.');
            setLoading(false);
            return;
        }

        // Fetch cart items from the backend
        axios.get(`${API_BASE_URL}/cartpage`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            console.log('API Response:', response.data);  // Log the response data
            setCartItems(response.data.cartItems || []);  // Ensure it's always an array
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching cart items:', error);
            if (error.response) {
                console.error('Error Response:', error.response.data);
                console.error('Error Status:', error.response.status);
            } else {
                console.error('Error Message:', error.message);
            }
            setError('Failed to load cart items. Please try again.');
            setLoading(false);
        });
    }, [token]);  // Re-run when token changes

    // Update quantity of the product
    const updateQuantity = (id, quantity) => {
        if (quantity <= 0) return;  // Prevent quantity from going below 1

        axios.put(`${API_BASE_URL}/cart/${id}`, 
            { quantity },
            { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(response => {
            setCartItems((prevItems) =>
                prevItems.map((item) =>
                    item.id === id ? { ...item, quantity } : item
                )
            );
            alert('Item quantity updated successfully!');
        })
        .catch(error => {
            console.error('Error updating quantity:', error);
            setError('Failed to update quantity. Please try again.');
        });
    };

    // Remove a product from the cart
    const removeProduct = (id) => {
        axios.delete(`${API_BASE_URL}/cart/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then(() => {
            setCartItems((prevItems) => prevItems.filter(item => item.id !== id));
            alert('Item removed from cart successfully!');
        })
        .catch(error => {
            console.error('Error removing product:', error);
            setError('Failed to remove product. Please try again.');
        });
    };

    // Calculate total price of cart items
    const calculateTotal = () => {
        return cartItems
            .reduce((total, item) => total + item.price * item.quantity, 0)
            .toFixed(2);  // Format as a fixed 2 decimal number
    };

    // Handle loading and error states
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    // If there are no items in the cart
    if (cartItems.length === 0) {
        return (
            <section className="cart-container mt-5">
                <h1>Shopping Cart</h1>
                <div>No items in your cart.</div>
            </section>
        );
    }

    return (
        <section className="cart-container mt-5">
            <h1>Shopping Cart</h1>
            {cartItems.map((item) => (
                <div className="cart-item" key={item.id}>
                    <div className="product-images">
                        <img
                            src={item.image_url}
                            alt={item.name}
                        />
                    </div>
                    <div className="item-details">
                        <p className="item-name">{item.name}</p>
                        <p className="item-price">{item.price} EGP</p>
                        <div className="quantity-controls">
                            <button onClick={() => updateQuantity(item.id, item.quantity - 1)} disabled={item.quantity <= 1}>-</button>
                            
                            <input
                                type="number"
                                value={item.quantity}
                                onChange={(e) => updateQuantity(item.id, parseInt(e.target.value))}
                                min="1"
                            />
                            <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
                        </div>
                    </div>
                    <div className="remove-item">
                        <span className="remove-icon" onClick={() => removeProduct(item.id)}>X</span>
                    </div>
                </div>
            ))}

            <div className="summary">
                <h3>Total: {calculateTotal()} EGP</h3>
                <button className="checkout-button" onClick={() => navigate('/checkout')}>Check Out</button>
            </div>
        </section>
    );
}
