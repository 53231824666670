import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.css';

export default function ProductCard({ product }) {
  
  const addToCart = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      alert("You need to log in to add items to the cart.");
      return;
    }

    try {
      // Get the base API URL from environment variable
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      await axios.post(
        `${API_BASE_URL}/cart/${product.id}`, // Use dynamic API URL
        { quantity: 1 }, // Default quantity can be adjusted
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      alert("Product added to cart successfully!");
    } catch (error) {
      console.error("Failed to add product to cart:", error);
      alert("Could not add product to cart.");
    }
  };

  return (
    <div className="product-card-container">
      <Link to={`/productdetails/${product.id}`}>  {/* Correctly link to ProductDetails */}
        <div className="product-card">
          <img src={product.image_url} className="image w-100" alt={product.name} />
          <div className="product-info">
            <h2 className="h6 text-center mt-4">{product.name}</h2>
            <h2 className="h6 text-center price mt-4">{product.price} EGP</h2>
            <button type="button" onClick={addToCart} className="btn button btn-success">
              Add To Cart
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
}
