import React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CSS/addproduct.css';

function AddProduct() {
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    name: '',
    price: '',
    image_url: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input changes dynamically
  function handleInputChange(e) {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  }

  // Handle form submission
  function handleSubmit(e) {
    e.preventDefault();

    // Validation: Check for empty fields
    if (!product.name || !product.price || !product.image_url) {
      setErrorMessage('All fields are required.');
      setTimeout(() => setErrorMessage(''), 3000); // Hide message after 3 seconds
      return;
    }

    // Send API request
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/addproduct`, product)
      .then((res) => {
        console.log(res);
        setSuccessMessage('Product added successfully!');
        setTimeout(() => setSuccessMessage(''), 3000); // Hide message after 3 seconds
        setProduct({ name: '', price: '', image_url: '' }); // Reset form fields
      })
      .catch((err) => {
        console.error(err);
        setErrorMessage('Failed to add product. Please try again.');
        setTimeout(() => setErrorMessage(''), 3000); // Hide message after 3 seconds
      });
  }

  return (
    <Fragment>
      <div className="product">
        <h1>Add New Product</h1>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Product Name"
            value={product.name}
            onChange={handleInputChange}
          />
          <input
            type="number"
            name="price"
            placeholder="Product Price"
            value={product.price}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="image_url"
            placeholder="Image URL"
            value={product.image_url}
            onChange={handleInputChange}
          />
          <button type="submit">Add Product</button>
        </form>

        {/* Success Message */}
        {successMessage && <div className="success-popup">{successMessage}</div>}

        {/* Error Message */}
        {errorMessage && <div className="error-popup">{errorMessage}</div>}
      </div>
    </Fragment>
  );
}

export default AddProduct;
