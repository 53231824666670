import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FoodCard from './../FoodCard/FoodCard';
import './style.css';
import { Link } from 'react-router-dom';

export default function AllFood() {
    const [products, setProducts] = useState([]);
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;  // Get the base URL from environment variable

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios
            .get(`${API_BASE_URL}/products`)  // Use dynamic API URL
            .then(({ data }) => {
                setProducts(data.slice());
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <div className="container mt-4">
                <div className="row">
                    <h1 className="text-center trending">All Food</h1>
                    
                    {/* Sidebar for category filter */}
                    <div className="col-12 col-sm-3">
                        <div className="sidebar d-flex flex-column">
                            <h4>Category</h4>
                            <div className='links'>
                                <Link to='/dogfood'>Dog Food</Link>
                            </div>
                            <div className='links'>
                                <Link to='/catfood'>Cat Food</Link>
                            </div>
                        </div>
                    </div>

                    {/* Product Grid */}
                    <div className="col-12 col-sm-9">
                        <div className="row">
                            {products
                                .filter((product) => product.name.toLowerCase().includes('food'))
                                .map((product) => (
                                    <div className="col-12 col-sm-6 col-md-3 mb-4" key={product.id}>
                                        <FoodCard product={product} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
