import axios from 'axios';
import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CSS/addproduct.css';

function UpdateProduct() {
    const navigate = useNavigate();
    const { x } = useParams(); // Get product ID from URL params
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [product, setProduct] = useState({
        name: "",
        price: "",
        image_url: "",
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    // Fetch product data when the component mounts
    useEffect(() => {
        axios
            .get(`${API_BASE_URL}/products/${x}`)
            .then((res) => {
                setProduct({
                    name: res.data.name,
                    price: res.data.price,
                    image_url: res.data.image_url,
                });
            })
            .catch((err) => {
                console.log(err);
                setErrorMessage("Failed to fetch product data.");
            });
    }, [x]); // Re-fetch data when the product ID changes

    function handleInput(e) {
        e.preventDefault();

        axios
            .post(`${API_BASE_URL}/updateproduct/${x}`, product)
            .then((res) => {
                console.log(res);
                setSuccessMessage("Product updated successfully!"); // Show success message
                setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
            })
            .catch((err) => {
                console.log(err);
                setErrorMessage("Failed to update product.");
            });
    }

    return (
        <Fragment>
            <div className='product'>
                <h1>Update Product</h1>

                {errorMessage && <div className="error-popup">{errorMessage}</div>}

                <form onSubmit={handleInput}>
                    <input
                        type='text'
                        placeholder='Product Name'
                        value={product.name}
                        onChange={(e) => setProduct({ ...product, name: e.target.value })}
                    />
                    <input
                        type='number'
                        placeholder='Product Price'
                        value={product.price}
                        onChange={(e) => setProduct({ ...product, price: e.target.value })}
                    />
                    <input
                        type='text'
                        placeholder='Image URL'
                        value={product.image_url}
                        onChange={(e) => setProduct({ ...product, image_url: e.target.value })}
                    />
                    {/* <input type='file' value={product.image_url} onChange={(e) => setProduct({ ...product, image_url: e.target.value })}/> */}
                    <button type='submit'>Update Product</button>
                </form>

                {successMessage && (
                    <div className="success-popup">
                        {successMessage}
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default UpdateProduct;
