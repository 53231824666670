import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './style.css';
import { Link } from 'react-router-dom';
import LittersCards from './../LittersCard/LittersCard';

export default function AllLitters() {
    const [products, setProducts] = useState([]);

    // Fetch the base API URL from the environment variable
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        axios
            .get(`${API_BASE_URL}/products`)  // Use the dynamic base URL
            .then(({ data }) => {
                setProducts(data.slice());
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <div className="container mt-4">
                <h1 className="text-center trending">Litters</h1>
                <div className="row d-flex justify-content-center">
                    {products
                        .filter((product) =>
                            product.name.toLowerCase().includes('litter')
                        )
                        .map((product) => (
                            <div className="col-md-2 mb-4" key={product.id}> {/* Adjust width as needed */}
                                <LittersCards product={product} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
}
