import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

export default function BedsCard({ product }) {
  return (
    <Link to={`../productdetails/${product.id}`}>
    <div className="product-card">
      <div className="product-image">
        <img src={product.image_url} alt={product.name} className="img-fluid w-100" />
      </div>
      <div className="product-info">
        <h6 className="text-center mt-3">{product.name}</h6>
        <h6 className="text-center price">LE {product.price} EGP</h6>
        <div className="text-center">
          <button className="btn btn-success mt-2">Add To Car</button>
        </div>
      </div>
    </div>
    </Link>
  );
}