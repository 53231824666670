import React, { useState } from 'react';
import "./style.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

export default function Header() {
  const { token, userName, logout } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/search?query=${searchQuery}`);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

 
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <Link to='/'>
            <img className="logo" src="/images/toka-06.png" alt="Logo" />
          </Link>
          <div className="d-flex align-items-center me-5">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search"
                className="search-input"
                value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              />
              <span className="search-icon">
                <i className="fas fa-search"></i>
              </span>
            </div>


            {/* Profile Icon */}
            <Link to='/profile'>
            <div className="profile-icon">
              <i className="fas fa-user"></i>
            </div>
            </Link>

            {/* Cart Icon */}
            <Link to='cartpage'>
              <div className="cart-icon">
                <i className="fa-solid fa-cart-shopping"></i>
              </div>
            </Link>

            {/* Conditional rendering for Sign In/Logout */}
            {token ? (
              <>
                <div className="sign-in">
                  <span>{userName}!</span>
                </div>
                <div className="sign-in" onClick={logout}>
                  <span style={{ cursor: 'pointer' }}>Logout</span>
                </div>
              </>
            ) : (
              <Link to="/login">
                <div className="sign-in">
                  <span>Sign In</span>
                </div>
              </Link>
            )}

            
          </div>
        </div>
      </div>
    </>
  );
}





