import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './style.css';
import { Link } from 'react-router-dom';
import ToysAndBowlsCard from './../Toys&BowlsCard/ToysAndBowlsCards';

export default function Bowls() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    function getData() {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Use environment variable for API URL

        axios
            .get(`${API_BASE_URL}/products`) // Use dynamic app-based API URL
            .then(({ data }) => {
                setProducts(data.slice());
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <div className="container mt-4">
                <div className="row">
                    <h1 className="text-center trending">Bowls</h1>
                    {/* Sidebar for category filter */}
                    <div className="col-md-3">
                        <div className="sidebar d-flex flex-column">
                            <h4>Category</h4>
                            <div className="links">
                                <Link to="/toys">Toys</Link>
                            </div>
                            <div className="links">
                                <Link to="/bowls">Bowls</Link>
                            </div>
                        </div>
                    </div>

                    {/* Product Grid */}
                    <div className="col-md-9">
                        <div className="row">
                            {products
                                .filter((product) => product.name.toLowerCase().includes('bowl'))
                                .map((product) => (
                                    <div className="col-md-3 mb-4" key={product.id}>
                                        <ToysAndBowlsCard product={product} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
