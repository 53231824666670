import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ProductCard from '../ProductCard/ProductCard';

export default function Search() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const location = useLocation();

  // Extract search query from URL
  const query = new URLSearchParams(location.search).get('query') || '';

  useEffect(() => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Get the base URL from environment variables

    // Fetch the products from the app-based API
    axios.get(`${API_BASE_URL}/products`)
      .then(response => {
        setProducts(response.data);
        filterProducts(response.data, query);
      })
      .catch(error => console.error("Error fetching products:", error));
  }, [query]);

  // Filter products based on query
  const filterProducts = (products, searchQuery) => {
    const filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  return (
    <div>
      <div className="container">
        <h1 className='h3 text-success mt-5'>Search Results for "{query}"</h1>
        <div className="row mt-5">
          {filteredProducts.length === 0 ? (
            <h3 className='text-center'>No products found</h3>
          ) : (
            filteredProducts.map(product => (
              <div className="col-md-3 mb-4" key={product.id}>
                <ProductCard product={product} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
