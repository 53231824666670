import React, { useState } from 'react';
import axios from 'axios';
import Joi from 'joi';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './style.css';

export default function SignUp() {
    const [dataInfo, setDataInfo] = useState({
        first_name: '',
        second_name: '',
        mobile: '',
        email: '',
        password: '',
    });
    const [errorsList, setErrorsList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to update the input data
    function getData(e) {
        const { name, value } = e.target;
        setDataInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    // Function to handle form submission
    async function handleSubmit(e) {
        e.preventDefault();
        const validateResult = validateSignUpForm();
        setErrorsList(validateResult?.error?.details || []);

        if (validateResult?.error) {
            console.log(validateResult?.error?.details);
            return; // Return early if validation fails
        }

        try {
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Get the base URL from environment variables

            const response = await axios.post(
                `${API_BASE_URL}/register`, // Using app-based API URL
                dataInfo
            );
            console.log('Registration successful:', response.data);
            // Navigate to login page after successful registration
            navigate('/login');
        } catch (err) {
            if (err.response) {
                // The request was made and the server responded with a status code that falls out of the range of 2xx
                setErrorMessage(err.response.data.message || 'An error occurred.');
                console.error('Error response:', err.response.data); // Log the error response for debugging
            } else if (err.request) {
                // The request was made but no response was received
                setErrorMessage('No response from the server. Please try again later.');
                console.error('Error request:', err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage('Error: ' + err.message);
                console.error('Error message:', err.message);
            }
        }
    }

    // Function to validate the sign-up form
    function validateSignUpForm() {
        const schema = Joi.object({
            first_name: Joi.string().min(1).required(),
            second_name: Joi.string().min(1).required(),
            mobile: Joi.string().pattern(new RegExp('^[0-9]{10}$')).required(),
            email: Joi.string()
                .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
                .required(),
            password: Joi.string()
                .pattern(new RegExp('^[a-zA-Z0-9!@#$%^&*]{6,30}$')) // Minimum length set to 6
                .required(),
        });
        return schema.validate(dataInfo, { abortEarly: false });
    }

    return (
        <div className="form-container">
            <h1>Create New Account</h1>
            <form className="create-account-form" onSubmit={handleSubmit}>
                {errorsList.map((err) => (
                    <h5 className='alert alert-danger' key={err.message}>{err.message}</h5>
                ))}
                {errorMessage && (
                    <h5 className='alert alert-danger'>{errorMessage}</h5>
                )}
                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        onChange={getData}
                    />
                </div>
                <div className="form-group">
                    <label>Second Name</label>
                    <input
                        type="text"
                        placeholder="Second Name"
                        name="second_name"
                        onChange={getData}
                    />
                </div>
                <div className="form-group">
                    <label>Mobile Number</label>
                    <input
                        type="tel"
                        placeholder="Mobile Number"
                        name="mobile"
                        onChange={getData}
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={getData}
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={getData}
                    />
                </div>
                <button type="submit" className="submit-button">Create Account</button>
            </form>
        </div>
    );
}
